export const EMPTY_SEQ_NUM_TYPE = {
  sequenceNumberId: 0,
  startNumber: 0,
  endNumber: 0,
  lastNumber: 0,
  validYear: 0,
  format: null,
  sequenceNumberTypeId: 0,
  sequenceNumberTypeName: null,
}
